import { nanoid } from 'nanoid'
import slugify from 'slugify'

export const generateFileKey = (filename: string) => {
  const filenameParts = filename.split('.')

  const fileType = filenameParts.pop()

  return `${nanoid(90)}-${slugify(filenameParts.join('.'))}.${fileType}`
}
