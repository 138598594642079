import { env } from '@/env'
import { BlobServiceClient } from '@azure/storage-blob'
import { blobToBuffer } from '../blob-to-buffer'

let blobServiceClient: BlobServiceClient

export async function azureStorageProvider() {
  if (!blobServiceClient)
    blobServiceClient = new BlobServiceClient(
      env.NEXT_PUBLIC_AZURE_STORAGE_CONNECTION,
    )

  async function getClient() {
    const containerName =
      env.NEXT_PUBLIC_ENV === 'development' ? 'local-app' : 'app'
    const containerClient = blobServiceClient.getContainerClient(containerName)

    if (!(await containerClient.exists())) {
      await containerClient.create()
    }

    return containerClient
  }
  type GetFileResponse<T extends string | undefined = undefined> =
    | (T extends string ? File : Blob)
    | null

  async function getFile<T extends string | undefined = undefined>(
    filekey: string,
    filename?: T,
  ): Promise<GetFileResponse<T>> {
    const client = await getClient()
    console.log({ filekey })
    const blockBlobClient = client.getBlockBlobClient(filekey)

    const downloadBlockBlobResponse = await blockBlobClient.download()

    const blobBody = await downloadBlockBlobResponse.blobBody

    console.log({ blobBody })

    if (!downloadBlockBlobResponse.blobBody || !blobBody) {
      return null
    }

    return (
      filename ? new File([blobBody], filename) : blobBody
    ) as GetFileResponse<T>
  }

  async function saveFile(
    filekey: string,
    filedata: Blob | File,
  ): Promise<void> {
    const client = await getClient()

    const blockBlobClient = client.getBlockBlobClient(filekey)

    const fileBuffer =
      filedata instanceof Buffer ? filedata : await blobToBuffer(filedata)

    const contentLength = Buffer.byteLength(fileBuffer)

    await blockBlobClient.upload(fileBuffer, contentLength)

    await blockBlobClient.setHTTPHeaders({
      blobContentType: filedata.type || undefined,
    })
  }

  async function deleteFile(filekey: string): Promise<void> {
    const client = await getClient()

    const blockBlobClient = client.getBlockBlobClient(filekey)

    await blockBlobClient.delete()
  }

  return {
    getFile,
    saveFile,
    deleteFile,
  }
}
