import { env } from '@/env'
import { authOptions } from '@/lib/auth-options'
import axios from 'axios'
import { Session, getServerSession } from 'next-auth'
import { getSession } from 'next-auth/react'
import { getHostname } from './get-host'

const api = axios.create()

let session: Session | null

const getApi = async (isSSR?: boolean) => {
  if (!session) {
    if (!isSSR) {
      session = await getSession()
    } else {
      session = await getServerSession(authOptions)
    }
  }

  const getURL = async () => {
    let hostname =
      typeof window !== 'undefined' && window.location.origin
        ? window.location.origin
        : ''

    if (!hostname) {
      hostname = (await getHostname()) || ''
    }

    const [illiAPI, abinbevAPI] = env.NEXT_PUBLIC_API_URL

    if (hostname?.includes('abinbev')) {
      return abinbevAPI
    }

    console.log({ hostname })
    return illiAPI
  }
  const baseURL = await getURL()
  console.log({ baseURL })

  api.defaults.baseURL = baseURL

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const $session = session as any

  if ($session?.user?.accessToken) {
    api.defaults.headers.common.Authorization = `Bearer ${$session?.user?.accessToken}`
  }

  return api
}

export { getApi }
