import { env } from '@/env'
import { azureStorageProvider } from './azure/storage'

type ProviderKey = typeof env.NEXT_PUBLIC_STORAGE_DRIVER
type ProviderValue = typeof azureStorageProvider

const providers: Record<ProviderKey, ProviderValue> = {
  azure: azureStorageProvider,
}

export const storageProvider = (...args: Parameters<ProviderValue>) =>
  providers[env.NEXT_PUBLIC_STORAGE_DRIVER](...args)
