import { createEnv } from '@t3-oss/env-nextjs'
import { z } from 'zod'

const environment = z.enum(['development', 'production', 'test'])

export const env = createEnv({
  server: {
    NEXTAUTH_URL: z.string().optional(),
    NEXTAUTH_SECRET: z.string().min(1),
  },
  client: {
    NEXT_PUBLIC_AZURE_STORAGE_CONNECTION: z.string().min(1),
    NEXT_PUBLIC_STORAGE_DRIVER: z.enum(['azure']).optional().default('azure'),
    NEXT_PUBLIC_ENV: environment.optional(),
    NEXT_PUBLIC_API_URL: z
      .string()
      .min(1)
      .transform((v) => {
        try {
          const data = z.array(z.string().url()).parse(JSON.parse(v))

          return data
        } catch {
          return []
        }
      }),
    NEXT_PUBLIC_APP_URL: z.string().url().min(1),
  },
  shared: {
    NODE_ENV: environment,
    VERCEL_ENV: z
      .enum(['production', 'preview', 'development'])
      .default('development'),
  },
  experimental__runtimeEnv: {
    NEXT_PUBLIC_AZURE_STORAGE_CONNECTION:
      process.env.NEXT_PUBLIC_AZURE_STORAGE_CONNECTION,
    NEXT_PUBLIC_STORAGE_DRIVER: process.env.NEXT_PUBLIC_STORAGE_DRIVER,
    NEXT_PUBLIC_API_URL: process.env.NEXT_PUBLIC_API_URL,
    NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV,
    NEXT_PUBLIC_APP_URL: process.env.NEXT_PUBLIC_APP_URL,
    NODE_ENV: process.env.NODE_ENV,
    VERCEL_ENV: process.env.VERCEL_ENV,
  },
})
